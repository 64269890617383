<template>
  <div>
    <loading-component />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CookieService } from '@/services/cookie';
import { AccountClient } from '@/services/models/accounts';
import * as Sentry from '@sentry/vue';

export default {
    components:{
        LoadingComponent: () =>
            import(
                /* webpackChunkName: "LoadingComponent"*/ '@/components/LoadingComponent/LoadingComponent'
            ),
    },
    data() {
        return {
            redirectTo: null,
            userId: null,
            userEmail: null,
        };
    },
    computed: {
        ...mapState({
            storeUserInfo: (state) => state.user_info_store.userInfo,
        }),
    },
    beforeMount() {
        this.uri = this.$route.query.uri ?? '/download-voicemod/';
        this.redirectTo = 'https://www.voicemod.net' + this.uri;
    },
    async mounted() {
        this.userId = this.storeUserInfo.userId;
        this.userEmail = this.storeUserInfo.email;

        if (!this.userId) {
            this.userId = this.getUserId();
        }

        if (!this.userEmail) {
            await this.getUserInfo();
        }

        await this.sendEmail();
    },
    methods: {
        getUserId() {
            const userIdCookie = CookieService.getCookie('userId');
            const userIdQueryParam = this.$route.query.userId;

            if (userIdCookie) {
                return userIdCookie;
            }
            if (userIdQueryParam) {
                return userIdQueryParam;
            }

            return null;
        },
        async getUserInfo() {
            try {
                const { data } = await AccountClient.getUserInfo(this.userId);
                this.setUserInfo(data);
            } catch (error) {
                Sentry.captureMessage('Can\'t retrieve user information for sending email');
                Sentry.captureException(error);
            }
        },
        setUserInfo(data) {
            this.$store.dispatch('user_info_store/axn_setUserInfo', {userId: this.userId, ...data});
            sessionStorage.setItem('userInfo', JSON.stringify({userId: this.userId, ...data}));
            this.userEmail = data.email;
        },
        redirectToMobilePage() {
            window.location.assign(this.redirectTo);
        },
        async sendEmail() {
            try {
                await AccountClient.sendMobileDownloadLink(this.userEmail);
                this.redirectToMobilePage();
            } catch (error) {
                Sentry.captureMessage('Can\'t send email');
                Sentry.captureException(error);
            }
        },
    },
};
</script>
